import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia'

import { useInfluencersStore } from './influencers'
import { useUserBandStore } from './userBand'

import { configToQs } from '~/helpers/configToQs'
import { submissionIsRead } from '~/helpers/submission/isRead'

import type { BandDashboardStatusFilter } from '~/enums/BandDashboardStatusFilter'
import type { PaginatedApiResponse } from '~/types/PaginatedApiResponse'
import type { ModdedAnswer, SubmissionV2 } from '~/types/submissionV2'

const state = () => ({
  filteredSubmissions: [] as SubmissionV2[],
  filteredSubmissionsTotal: 0,
  filter: {
    offset: 0,
    limit: 50,
  },
  selectedSubmissionIds: [] as SubmissionV2['id'][],
})

export type SubmissionsState = ReturnType<typeof state>

export const useSubmissionsStore = defineStore('submissions', {
  state: (): SubmissionsState => ({ ...state() }),
  actions: {
    SET_FILTER({ offset, limit }: SubmissionsState['filter']) {
      this.filter.offset = offset
      this.filter.limit = limit
    },
    SET_TOTAL_SUBMISSION_AMOUNT_FOR_CURRENT_SET(total: number) {
      this.filteredSubmissionsTotal = total
    },
    SET_FILTERED_SUBMISSIONS(submissions: SubmissionV2[]) {
      this.filteredSubmissions.splice(
        0,
        this.filteredSubmissions.length,
        ...submissions,
      )
    },
    SET_SELECTED_SUBMISSIONS_IDS(submissionIds: SubmissionV2['id'][]) {
      this.selectedSubmissionIds = submissionIds
    },
    UPDATE_BY_ID(
      submission: Partial<SubmissionV2> & { id: SubmissionV2['id'] },
    ) {
      const index = this.filteredSubmissions.findIndex(
        (e) => e.id === submission.id,
      )

      if (index === -1) return

      const newSubmission = {
        ...this.filteredSubmissions[index],
        ...submission,
      }

      this.filteredSubmissions.splice(index, 1, newSubmission)
    },
    async FETCH({
      offset = 0,
      limit = 20,
      trackId = undefined,
      campaignId = undefined,
      statuses = [],
    }: {
      offset: number
      limit: number
      trackId?: number
      campaignId?: number
      statuses: BandDashboardStatusFilter[] | undefined
    }) {
      const pinia = getActivePinia()
      const userBandStore = useUserBandStore(pinia)
      const influencersStore = useInfluencersStore(pinia)
      const opportunities = statuses.length ? statuses.join(',') : undefined
      const filter: SubmissionsState['filter'] = { offset, limit }
      this.SET_FILTER(filter)

      try {
        const response = await $coreFetch.$get<
          PaginatedApiResponse<SubmissionV2>
        >(
          `/submission/submissions/?${configToQs({
            band_id: userBandStore.id,
            offset,
            limit,
            opportunities,
            track_ids: trackId,
            campaign_ids: campaignId,
          })}`,
        )

        this.SET_FILTERED_SUBMISSIONS(response.results)
        this.SET_TOTAL_SUBMISSION_AMOUNT_FOR_CURRENT_SET(response.count)

        // This can actually be fully async ! No need to wait for this :>
        const influencerIds = response.results.map(
          (e: SubmissionV2) => e.influencer,
        )
        influencersStore.FETCH_SET(influencerIds, true)

        return response.results
      } catch (_) {
        return []
      }
    },
    async UPDATE_SUBMISSION_WITH_ID(submissionId: number) {
      const userBandStore = useUserBandStore(getActivePinia())

      try {
        const submission = await $coreFetch.$get<SubmissionV2>(
          `/submission/submissions/${submissionId}/?${configToQs({
            band_id: userBandStore.id,
          })}`,
        )

        this.UPDATE_BY_ID(submission)
      } catch (_) {
        // mute error
      }
    },
    async RATE_SUBMISSION({
      answerId,
      rating,
      criteriaIds,
    }: {
      answerId: number
      rating: number
      criteriaIds: number[]
    }) {
      const submission = this.filteredSubmissions.find(
        (s) => s.answer?.id === answerId,
      )
      if (!submission)
        throw new Error('Submission not found for answer id: ' + answerId)

      const postData = {
        answer: answerId,
        score: rating,
        criteria: criteriaIds,
      }
      await $coreFetch.$post('/answer/rate/', postData)

      const submissionUpdate: Partial<SubmissionV2> & {
        id: SubmissionV2['id']
      } = {
        id: submission.id,
        answer: {
          ...(submission.answer as ModdedAnswer),
          rating,
        },
      }

      this.UPDATE_BY_ID(submissionUpdate)
    },
    async MARK_AS_READ({ submissionId }: { submissionId: number }) {
      const submission = this.filteredSubmissions.find(
        ({ id }) => id === submissionId,
      )

      // Skip if already read
      if (submission && submissionIsRead(submission)) return

      const updatedSubmission = await $coreFetch.$get<SubmissionV2>(
        `/submission/read/${submissionId}/`,
      )
      this.UPDATE_BY_ID(updatedSubmission)
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useSubmissionsStore, import.meta.hot))
